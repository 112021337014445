<template>
<div id="searchPanel" >
    <el-row>
    <el-col :span="24">
    <criteriaPanel :chooseCity="chooseCity"  @criteriaChanged="criteriaChanged"/>
    </el-col>
   </el-row>

   <el-row :gutter="10"> 
       <el-col :span="18">
        <el-pagination
  :page-size="pageData.pageSize"
  :current-page="pageData.pageNum"
  layout="total,prev, pager, next"
  :total="pageData.total"
  @current-change="changePage"
  @prev-click="prePage"
  @next-click="nextPage">
</el-pagination>
   

    <div v-if="pageData.total > 0">
        <el-link v-for="item in pageData.data" :href="getDetailLink(item)" target="_blank">
             <propertyQueryListCard  :propertyBean="item">
            </propertyQueryListCard>
        </el-link>
       
            </div>
    <div v-else  class="card-list">
        <h3>敬请期待</h3>
        </div>
       </el-col>
        <el-col :span="6">
            <el-row>
                <el-col>&nbsp;</el-col>
            </el-row>
            <el-row>
                <el-col>&nbsp;</el-col>
            </el-row>
            <AdvisorPanel></AdvisorPanel>
        </el-col>
    </el-row>

</div>

</template>

<script>

import {getIndexContent} from '@/api/commonFunc.js'
import {getLatestGroupon,getHotPropertyArea,queryPropertyByCriteria} from '@/api/propertyQuery.js'
import propertyQueryListCard from '../propertyQueryListCard.vue'

import criteriaPanel from '../criteria'
import AdvisorPanel from './AdvisorPanel'

export default {
    name:"SearchPanel",
    components:{
       criteriaPanel,
       propertyQueryListCard,
       AdvisorPanel
        },
    props:{
        chooseCity:String,
        propertyColumn:String
    },
    
    watch:{
       chooseCity:  {
            handler(newVal,oldVal) {
                this.clearPageData();
            },
            immediate: true
        }
        
    },
    data(){
       return{
           pageData:{
                total:0,
                pageNum:1,
                pageSize:5,
                data:[]
           },
           queryFormSaved:null,
           
       }
       
    },
    created(){
     
    },
    computed:{
        
    },
    methods:{
       criteriaChanged(queryForm){
           this.queryFormSaved = queryForm;
          this.doQuery(this.queryFormSaved);
       },
       clearPageData(){
           this.pageData = {
                total:0,
                pageNum:1,
                pageSize:this.pageData.pageSize,
                data:[]
           };
       },
       doQuery(queryForm){
          let _this = this;
           queryForm['column'] = this.propertyColumn;
           queryForm['pageNum'] = this.pageData.pageNum;
           queryForm['pageSize'] = this.pageData.pageSize;
          queryPropertyByCriteria(queryForm).then(res=>{
              
              _this.pageData = res.data;
          })
       },
       changePage(curPage){
           this.pageData.pageNum = curPage;
          this.doQuery(this.queryFormSaved);
       },
       nextPage(){
           if(this.queryFormSaved != {}){
                this.pageData.pageNum++;
                this.doQuery(this.queryFormSaved );
           }
          

       },
       prePage(){
            if(this.queryFormSaved != {}){
                this.pageData.pageNum--;
                this.doQuery(this.queryFormSaved);
            }
           
       },
       getDetailLink(item){
           return "./index.html?propertyId="+item.id;
       }
    }
}
</script>

<style>
 .card-list {
    font-size: 8px;
    text-align: center;
    
  }
 
</style>