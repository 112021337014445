<template>
<div id="indexMainPage"> 
    <el-row>
        <el-col :span="16">
            <mainpageAd></mainpageAd>
        </el-col>
        
        <el-col :span="8">
            <div align="center">
            <el-link :underline="false"><h3>关于安个新家|联系我们</h3></el-link>
            <h3>委托找房</h3>
            <pre>
            留下您的任意需求
        如预算，意向区域，意向楼盘等
        我们将为您推荐合适的楼盘
        或与楼盘洽谈进行团购合作
        </pre>
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item>
            <el-input type="textarea" v-model="form.requirement" placeholder="请输入您的任意需求（如：常熟地区，100万左右的三房。或：常熟碧桂园星亦名筑团购）"></el-input>
            </el-form-item>
            <el-form-item  prop="phone">
             <el-input v-model="form.phone" placeholder="手机号（必填）"></el-input>
            </el-form-item>
             <el-form-item>
    <el-button type="primary" @click="onSubmit">提交</el-button>
      </el-form-item>
        </el-form>
            </div>


            
        </el-col>
    </el-row>
  
    <el-row>
        <div class="card-list">
            <h2>最新开启团购</h2>
        </div>
        <el-divider></el-divider>
        <el-row :gutter="10">
            <el-col v-for="item in latestList" :span="6" :key="item">
                 <el-link  :href="getDetailLink(item)" target="_blank">
                <propertyCard :propertyBean="item"></propertyCard>
                 </el-link>
            </el-col>
        </el-row>
        
    </el-row>
 <el-row><el-col :span="24" ><el-button @click="seeMore">查看更多>></el-button></el-col></el-row>
     <el-row>
        <div class="card-list">
            <h2>热门楼盘</h2>
        </div>
        <el-divider></el-divider>
        <el-row :gutter="5" v-for="r in latestRows" :key="r">
            <el-col v-for="i in (r<latestRows?3:hotestList.length%3)" :span="6" :key="i">
                <el-link  :href="getDetailLink(hotestList[3*(r-1)+(i-1)])" target="_blank">
                <propertyCard :propertyBean="hotestList[3*(r-1)+(i-1)]"></propertyCard>
                 </el-link>
                
            </el-col>
        </el-row>
        
    </el-row>
    <el-row><el-col :span="24" ><el-button @click="seeMore">查看更多>></el-button></el-col></el-row>
</div>
</template>

<script>

import {getIndexContent,customerRegist} from '@/api/commonFunc.js'
import {getLatestGroupon,getHotPropertyArea} from '@/api/propertyQuery.js'
import mainpageAd from './MainPageAd'
 import propertyCard from '../propertyCard'

export default {
    name:"IndexMainPage",
    components:{
        mainpageAd,
        propertyCard
        },
    props:{
        chooseCity:String
    },
    
    watch:{
       
        chooseCity:  {
            handler(newVal,oldVal) {
               getLatestGroupon(newVal).then(res=>{
                    this.latestList = res.data;
                });
                getHotPropertyArea(newVal).then(res=>{
                    this.hotestList = res.data;
                });
            },
            immediate: true
        }
    },
    data(){
        var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else {
          const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
          console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号'));
          }
        }
      };
      return {
    			form:{
                   requirement:'',
                    phone:''
                },
          rules: {
          phone: [
            {validator: checkPhone, trigger: 'blur'}
          ]
        },
        latestList:[],
        hotestList:[]
    		}
       
    },
    created(){
      getIndexContent().then(res=>{
        this.ads = res.data
      });
      
    },
    computed:{
        latestRows: function(){
            if(this.hotestList){
                return Math.ceil(this.hotestList.length/3)
                
            }
            return 0;
        }


    },
    methods:{
        onSubmit(){
            let _this = this;
            this.$refs['form'].validate((valid) => {
            if (valid) {
               // let formData = {'propertyId':'5f77dd94cfe4cd42e100d1d9','telephone':this.form.phone,'content':this.form.requirement,'type':"委托买房"};
               let formData = {'telephone':this.form.phone,'content':this.form.requirement,'type':"委托买房"};
                customerRegist(formData).then(res=>{
                    _this.$message("提交成功，我们会尽快联系您");
                });
            } else {
                
                return false;
            }
        });
           
        },
        seeMore(){
            console.log('see more');
            this.$emit('switchPanel','search');
        },
        getDetailLink(item){
           return "./index.html?propertyId="+item.id;
       }
    }
}
</script>

<style>

 .card-list {
    font-size: 8px;
    text-align: center;
    
  }
  .blockBar{
      background: darkcyan;
      align-items: center;
      align-content: center;
  }
</style>