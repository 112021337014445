<template>
<div>
    <el-tabs  @tab-click="handleClick">
    <el-tab-pane label="公交" name="gj">公交</el-tab-pane>
    <el-tab-pane label="地铁" name="dt">地铁</el-tab-pane>
    <el-tab-pane label="医院" name="yy">医院</el-tab-pane>
    <el-tab-pane label="银行" name="yh">银行</el-tab-pane>
    <el-tab-pane label="休闲娱乐" name="ylxx">休闲娱乐</el-tab-pane>
    <el-tab-pane label="购物" name="gw">购物</el-tab-pane>
    <el-tab-pane label="餐饮" name="cy">餐饮</el-tab-pane>
    <el-tab-pane label="运动健身" name="ydjs">运动健身</el-tab-pane>
  </el-tabs>
      
    <baidu-map class="bm-view" style="height:600px" :center="property.address.center" :zoom="property.address.zoom"   :scroll-wheel-zoom="true" @click="getClickInfo" @ready="mapReady">
        
         <bm-marker :position="property.address.center" :dragging="false" animation="BMAP_ANIMATION_BOUNCE" />
         <!-- <bm-info-window title="房产信息" :position="address.center"   :show="true" />  --> 
         <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />

        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :show-address-bar="true"
          :auto-location="true"
        />
      </baidu-map>
</div>
    
</template>

<script>
export default {
    name:"BaiduMapPanel",
    props:{
        property:{
            type:Object,
            default() {
                return {}
                }
        }
    },
     data () {
        return {
            map:{},
            local:{},
            point:{}
          }
  },
    methods: {
      handleClick(tab, event) {
          console.log(tab);
          
        
        //  var marker = new BMap.Marker(point);
        //  this.map.addOverlay(marker);
         //  跳动标注
        //  marker.setAnimation(BMAP_ANIMATION_BOUNCE);
         this.local.searchNearby(tab.label,this.point,1000);
      },
      mapReady({ BMap, map }) {
        console.log("mapReady");
        
        // var address = this.property.address.center;
        // var marker = new BMap.Marker(new BMap.Point(address.lng,address.lat)); // 创建标注
        // var text = this.property.total+"万元";
        // var point = new BMap.Point(address.lng,address.lat);
        // map.centerAndZoom(point,12);
        // var label = new BMap.Label(text,{ // 覆盖文字信息
        //     offset: new BMap.Size(0, 0)
        // });
        // var opts = {
        //         width : 250, // 信息窗口宽度
        //         height: 300, // 信息窗口高度
        //         title : this.property.name , // 信息窗口标题
        //     enableMessage:true,//设置允许信息窗发送短息
        // }
        // var content = `<div>`;
        //     content += `<img src=${this.property.pics[0]} style="height:100px;width:100%;margin:0;margin-top:20px;padding:0;">`;
        //     content += `<div><span style="font-size:28px;font-weight:bold;color:#ED4242;">${this.property.total}</span></div>`;
        //     // content += `<div style="height:100px;display:flex;justify-content:space-between;align-items:center;"><div><p style="height:10px;font-size:24px;color:#ff9900;"><span style="font-size:18px;color:#FFE300;">评分：</span>${this.houselist[i].score}<p><p style="height:10px;">${this.houselist[i].zh_name}</p></div>`;
        //     // content += `<span style="width:50px;height:50px;line-height:50px;text-align:center;font-size:12px;border-radius:50%;cursor: pointer;background:#FFE300;" onclick="godetails(${this.houselist[i].id})">详情</span>`;
        //     content += `</div>`;
        //     content += `</div>`;

        //     label.setStyle({
        //     background: "#FFE300",
        //     textAlign:"center",
        //     fontSize:'18px',
        //     border:'none',
        //     padding:'7px',
        //     });
 
 
        //     marker.setLabel(label); //把label设置到maker上
        //     map.addOverlay(marker); // 将标注添加到地图中
        //     this.addClickHandler(content,marker);
        
        this.map = map;
        
         this.point = new BMap.Point(this.property.address.center.lng, this.property.address.center.lat);
         this.map.centerAndZoom(this.point,15);
         var circle = new BMap.Circle(this.point,1000,{fillColor:"#ccc", strokeWeight: 1 ,fillOpacity: 0.3, strokeOpacity: 0.5});
         this.local =  new BMap.LocalSearch(this.map, {renderOptions: {map: this.map, autoViewport: false}});
         this.map.addOverlay(circle);

      },
       addClickHandler(content,marker){
            marker.addEventListener("mouseover",function(e){
                openInfo(content,e)});
       },
       getClickInfo({ type, target, point, pixel, overlay }) {
           console.log("clickedInfo");
       },
        openInfo(content,e){
            var p = e.target;
            var point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
            var infoWindow = new BMap.InfoWindow(content,opts); // 创建信息窗口对象
            map.openInfoWindow(infoWindow,point); //开启信息窗口
        }
    }
    
}
</script>

<style scoped>

 .bm-view{
    width: 100%;
    height: 400px;
  }

</style>