import request from '@/utils/request'
export function getLatestGroupon(city) {
    return request({
      url: '/api/property/latest',
      method: 'get',
      params: {city:city,limit:4}
    })
  }

  export function getHotPropertyArea(city) {
    return request({
      url: '/api/property/hotest',
      method: 'get',
      params: {city:city,limit:6}
    })
  }

  export function queryPropertyByCriteria(queryForm){
    console.log("invoke query");
    return request({
      url: '/api/property/listByCriteria',
      method: 'get',
      params: {county:queryForm.county[0], 
        totalPrice:queryForm.totalPrice[0],
        houseType:queryForm.houseType[0],
        tag:queryForm.tag[0],
        column:queryForm.column,
        propertyName:queryForm.propertyName,
        phoneFlag:queryForm.phoneflag,
        pageNum:queryForm.pageNum,
        pageSize:queryForm.pageSize
      }
    })
  }


  export function getPropertyDetail(id){
    return request({
      url:'/api/property/detail',
      method:'get',
      params:{
        propertyId:id
      }
    })

  }

  export function getActivitiesBy(id){
    return request({
      url:'/api/property/activities',
      method:'get',
      params:{
        propertyId:id
      }
    })
  }

  export function getAdviseList(params){
    return request({
      url:'/api/property/adviseList',
      method:'get',
      params:params
    })
  }