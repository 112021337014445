<template>
  <el-carousel :interval="5000" arrow="always" height="500px">
    <div v-if="ads.length==0">
   <el-carousel-item v-for="item in 4" :key="item">
      <h3>{{ item }}</h3>
    </el-carousel-item>
    </div>
 
   
    <div v-else>
 <el-carousel-item v-for="item in ads" :key="item.id">
      <el-image :src="item.url" target="_blank"></el-image>
    </el-carousel-item>
    </div>
  </el-carousel>
</template>

<script>

import {getIndexContent} from '@/api/commonFunc.js'
export default {
    name:"mainpageAd",
    data(){
      return {
    			ads:[]
    		}
       
    },
    created(){
      getIndexContent().then(res=>{
        this.ads = res.data
      })
    }
}
</script>

<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>