<template>
<div>

    <el-card :body-style="{ padding: '4px' }">
      <!-- <img :src="propertyBean.pics[0]" class="image"> -->
      <el-image :src="propertyBean.pics[0]" class="image"> </el-image>
      <div class="card-content">
        <h2>{{propertyBean.name}}</h2>
        <div >
          <el-row>
            <el-col :span="12">
              <div>{{areaCodeNameMapping[propertyBean.region[1]]}}-{{areaCodeNameMapping[propertyBean.region[2]]}}</div>
              <div>{{propertyBean.area}}</div>
            </el-col>
            <el-col :span="12">
              <div><font color='red'><h4>{{propertyBean.avgPrice}}元/平</h4></font></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>

</div>
</template>
<style>
  .card-content {
    font-size: 8px;
    text-align: center;
  }

  
</style>

<script>
import {getValFromGlobal} from '@/utils/global.js'

export default {
  name:"propertyCard",
  props:{
    propertyBean: {
        type: Object,
        default () {
            return {}
        }
    }
  },
  data() {
    return {
      currentDate: new Date()
    };
  },
  computed:{
    areaCodeNameMapping:function(){
       return getValFromGlobal("areaCodeNameMapping");
    }
  }
}
</script>