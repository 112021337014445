<template>
<div id="advisorPanel"> 
    <el-card >
        <el-row>
        <span class="headerText">参与购房团，每周开团</span>
        </el-row>
        <el-row>
        <span >全程免费：</span> 
        <el-tag type="success">专车接送</el-tag> <el-tag type="danger">专人砍价</el-tag> <el-tag type="normal">买贵包退</el-tag>
        </el-row>
        <el-row>
        <span>本期截止：{{latestBuyTime()}}</span>
        </el-row>
        <el-row>
        <span>已有89名参加</span>
        </el-row>
        <el-form ref="form" :model="form" :rules="rules">
             <el-input v-model="form.phone" placeholder="手机号（必填）"></el-input>
        <el-button type="danger" @click="onSubmit" >立即预约</el-button>
        </el-form>
       
    </el-card>
    
    <el-card >
      <span> 咨询购房师</span>
      <advisor-card v-for="item in advisorList" :advisor="item"></advisor-card>
    </el-card>
</div>
  
</template>

<script>


import AdvisorCard from '../AdvisorCard.vue';
import {getAdvisors} from '@/api/commonFunc.js'



export default {
    name:"AdvisorPanel",
    components:{
       AdvisorCard
        
        },
    props:{
       
    },
    
    watch:{
       
      
    },
    data(){
        var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else {
          const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
          console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号'));
          }
        }
      };
      return {
    			form:{
                    phone:''
                },
          rules: {
          phone: [
            {validator: checkPhone, trigger: 'blur'}
          ]
        },
         advisorList:[]
    		}
       
    },
    created(){
     getAdvisors().then(res=>{
       this.advisorList = res.data;
     });
      
    },
    computed:{
       
    },
    methods:{
        onSubmit(){
            this.$refs[formName].validate((valid) => {
            if (valid) {
                alert('need to submit!');
            } else {
                
                return false;
            }
        });
           
        },
        latestBuyTime(){
          var now = new Date();
          var nowTime = now.getTime();
    var day = now.getDay();
    var oneDayTime = 24 * 60 * 60 * 1000;
    var SundayTime = nowTime + (6 - day) * oneDayTime; //显示周六
    var months = new Date(SundayTime).getMonth() + 1;
    var dates = new Date(SundayTime).getDate();
    var years = new Date(SundayTime).getFullYear();
          return years + "-" + months + "-" + dates;
        }
    }
}
</script>

<style>

 .card-list {
    font-size: 8px;
    text-align: center;
    
  }
  .blockBar{
      background: darkcyan;
      align-items: center;
      align-content: center;
  }
   
  .headerText{
      font-size: 15px;
      text-align: center;
  }
</style>