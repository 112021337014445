export function bindToGlobal(obj, key='_const') {
    if (typeof window[key] === 'undefined') {
        window[key] = {};
    }
    
    for (let i in obj) {
        window[key][i] = obj[i]
    }
}

export function getValFromGlobal(key='_const') {
    return window[key]
}

export const server_base_url ="https://www.qumaifang.com.cn/"

