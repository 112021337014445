<template>
<el-card class="box-card">
<div>
    <el-row>
        <el-col :span="1"><h3>区域:</h3></el-col>
        <el-col :span="20" >
            <el-checkbox-group v-model="queryForm.county" :max="1" :min="0" @change="handleCriterialCheckedChange">
                <el-checkbox-button :label="chooseCity" name="type" >不限</el-checkbox-button>  
                <el-checkbox-button v-for="item in countyOptions" :label="item.code">{{item.label}}</el-checkbox-button>
            </el-checkbox-group>
        </el-col>
    </el-row>
    <el-row>
         <el-col :span="1"><h3>总价:</h3></el-col>
        <el-col :span="20">
             <el-checkbox-group v-model="queryForm.totalPrice" :max="1" :min="0" @change="handleCriterialCheckedChange">
                  <el-checkbox-button label="NO-LIMITED" name="type" >不限</el-checkbox-button>  
       <el-checkbox-button v-for="item in totalPriceOptions" :label="item.code">{{item.label}}</el-checkbox-button>
    </el-checkbox-group>
 
        </el-col>
    </el-row>
    <el-row>
         <el-col :span="1"><h3>户型:</h3></el-col>
        <el-col :span="20">
             <el-checkbox-group v-model="queryForm.houseType" :max="1" :min="0" @change="handleCriterialCheckedChange">
                  <el-checkbox-button label="NO-LIMITED" name="type" >不限</el-checkbox-button>  
       <el-checkbox-button v-for="item in houseTypeOptions" :label="item.k">{{item.v}}</el-checkbox-button>
    </el-checkbox-group>
        </el-col>
    </el-row>
    <el-row>
         <el-col :span="1"><h3>类型:</h3></el-col>
        <el-col :span="20">
       
      <el-checkbox-group v-model="queryForm.tag" :max="1" :min="0" @change="handleCriterialCheckedChange">
           <el-checkbox-button label="NO-LIMITED" name="type" >不限</el-checkbox-button>  
       <el-checkbox-button v-for="item in tagOptions" :label="item.k">{{item.v}}</el-checkbox-button>
    </el-checkbox-group>
 
         </el-col>
        
    </el-row>
</div>
</el-card>  
</template>

<script>
import {getCountyOptions,getHouseType,getHouseTags} from  '@/api/commonFunc.js'
export default {
    name:'criteriaPanel',
    props:{
        chooseCity:String
    },
    data(){
        return {
            queryForm:{
                county:[],
                totalPrice:[],
                houseType:[],
                tag:[]

                
            },
            totalPriceOptions:[
                    {'code':'u100','label':'100万以下'},
                    {'code':'a100u150','label':'100-150万'},
                    {'code':'a150u200','label':'150-200万'},
                    {'code':'a200u300','label':'200-300万'},
                    {'code':'a300','label':'300万以上'},

                ],
               
                houseTypeOptions:[],
                tagOptions:[],
                countyOptions:[]
                
        }
    },
    watch:{
       
        chooseCity:  {
            handler(newVal,oldVal) {
                getCountyOptions(newVal).then(res=>{
                    this.countyOptions = res.data;
                });
                this.queryForm={
                county:[],
                totalPrice:[],
                houseType:[],
                tag:[]
            };
            },
            immediate: true
        }
    },
    created(){
        getHouseType().then(res=>{
            this.houseTypeOptions = res.data
        });
        getHouseTags().then(res=>{
            this.tagOptions = res.data
        })
    },
    methods:{
      handleCriterialCheckedChange(value){
          
          var qFormData ={};
          Object.assign(qFormData,this.queryForm);
        //   var qFormData = Object.create(this.queryForm);
          if(qFormData.county.length == 0){
              qFormData.county = [this.chooseCity];
          }
          this.$emit('criteriaChanged',qFormData);
          
      }
      
    }
    
}
</script>

<style scoped>

</style>