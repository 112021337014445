import request from '@/utils/request'
export function getHotCity(params) {
  return request({
    url: '/api/hotcity/hotcityList',
    method: 'get',
    params: params
  })
}

export function areaCodeMap() {
  return request({
    url: '/api/config/areaCodeMap',
    method: 'get',
    params: { }
  })
}


export function getCatagoryTag(){
  return request({
    url:'/api/siteConfigData/getConfig',
    method:'get',
    params:{group:'TAGS',varName:'category'}
  })
}


export function getIndexContent(){
  return request({
    url:'/api/indexContent/showList',
    method:'get',
    params:{}
  })
}


export function getCountyOptions(city){
  return request({
    url:'/api/config/countyList',
    method:'get',
    params:{'cityCode':city}
  })
}

export function getHouseType(){
  return request({
    url:'/api/siteConfigData/getConfig',
    method:'get',
    params:{'group':'TAGS','varName':'houseType'}
  })
}

export function getHouseTags(){
  return request({
    url:'/api/siteConfigData/getConfig',
    method:'get',
    params:{'group':'TAGS','varName':'tags'}
  })
}


export function getAdvisors(){
  return request({
    url:'/api/user/advisors',
    method:'get',
    params:{'count':3}
  })
}

export function customerRegist(data){
  return request({
    url:'/api/access/customerRegister',
    method:'post',
    data
  })

}


export function zanIncrease(data){
  return request({
    url:'/api/access/zanIncrease',
    method:'post',
    data
  })

}
