<template>
    <div class="info">
       <el-menu
  default-active="1"
  class="el-menu-demo"
  mode="horizontal"
  @select="handleSelect"
  background-color="#545c64"
  text-color="#fff"
  active-text-color="#ffd04b">
  <el-menu-item index="indexPage" >首页</el-menu-item>
  <el-menu-item  v-for="(value, key, index) in menu" :index="value.k">{{value.k}}</el-menu-item>
  <el-menu-item><el-input ></el-input> </el-menu-item>
  <el-menu-item><el-button>搜索</el-button></el-menu-item>
  <el-menu-item>热门搜索：</el-menu-item>
 <el-menu-item index="万科">万科</el-menu-item>
 <el-menu-item index="碧桂园">碧桂园</el-menu-item>
</el-menu>
    </div>
</template>

<script>

    import {getCatagoryTag} from '@/api/commonFunc.js'
    export default {
        name: "menuBar",
        components: {

        },
    	data(){
    		return {
    			menu:[]
    		}
        },
      created() {
            getCatagoryTag().then(res=>{
                this.menu = res.data;
            })
        },
        methods: {
        handleSelect(key, keyPath) {
            
            this.$emit('menuSelected',key)
        }
    }
    }
</script>

<style>
.info{
  text-indent: 0%;
  text-align:center;
  font-style:normal;
  font-weight:normal;
  font-size: 7px;
  font-family:"Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  color: rgb(216, 222, 228);
  background-color: rgb(49, 154, 180);
}

</style>
