<template>
<el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <!-- <span>报名团购可享限量内部优惠<span class="red">额外折上折!</span></span>
<span><span class="red">261人</span>已报名！</span> -->

<el-form ref="form" :model="form" :rules="rules" label-width="100px">
  <el-form-item>
    <span v-html="grouponInfo.information"></span>
  </el-form-item>
    <el-form-item label="手机号" prop="phone">
    <el-input v-model="grouponInfo.phone" placeholder="手机号（必填）"></el-input>
    </el-form-item>

    <el-form-item v-show="grouponInfo.showContent">
      <el-input type="textarea" v-model="grouponInfo.content" :placeholder="grouponInfo.contentPlaceHoder"></el-input>
    </el-form-item>
</el-form>

  <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="danger" @click="onSubmit">确 定</el-button>
  </span>
</el-dialog>
</template>
<script>
import {customerRegist} from '@/api/commonFunc.js'
 export default {
     name:"GrouponFormPanel",
     props:{
        dialogVisible: {
          type:Boolean,
          default:false
          },
          
          grouponInfo:{
          type:Object,
          default: function () {
            return { 
              information:'',
              type: '委托买房',
              content:'',
              estateid:'',
              phone:'',
              showContent:false,
              contentPlaceHoder:''
             }
          }
        }
     },
    data() {
         var checkPhone = (rule, value, callback) => {
           let phone = this.grouponInfo.phone;
        if (!phone) {
          return callback(new Error('手机号不能为空'));
        } else {
          const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
          console.log(reg.test(phone));
          if (reg.test(phone)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号'));
          }
        }
      };
      return {
          form:{},
           rules: {
            phone: [
              {validator: checkPhone, trigger: 'blur'}
            ]
          },
       
      };
    },
    watch:{
      // grouponInfo:  {
      //       handler(newVal,oldVal) {
      //          this.grouponInfo = newVal;
      //       },
      //       immediate: true
      // }
    },
    methods: {
      handleClose(done) {
        this.$emit("grouponDialogClosed");
      },
        onSubmit(){
            let _this = this;
            this.$refs['form'].validate((valid) => {
            if (valid) {
              
               let formData = {'telephone':_this.grouponInfo.phone,'content':_this.grouponInfo.content,'type':_this.grouponInfo.type,'propertyId':_this.grouponInfo.estateid};
                customerRegist(formData).then(res=>{
                  
                    _this.$message.success("提交成功，我们会尽快联系您");
                });
                this.handleClose();
            } else {
               return false;
            }
        });
           
        },
    }
  };
</script>

<style scoped>
.red{
    color: red;
    font-size: 12px;
}
</style>