<template>
<div id="detailBaseInfo"> 
    <el-card>
    <el-row><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row :gutter="20">
        <el-col :span="12">
             <el-carousel :interval="1000" type="card" height="400px">
             <el-carousel-item v-for="item in property.pics" :key="item">
      <el-image :src="item" style="width: 400px; height: 400px" fit="fill"></el-image>
    </el-carousel-item>
             </el-carousel>
        </el-col>
        <el-col :span="10">
            <el-row><el-col> <span class="esname">{{property.name}}</span> <span><el-tag v-for="tag in property.tags" type="success" :key="tag">{{tag}}</el-tag></span></el-col></el-row>
            <el-row><el-col><span class="esprop">均价:<span class="price"> {{property.avgPrice}}</span>元/平</span></el-col></el-row>
           <el-row><el-col><span class="esprop">面积： {{property.area}}</span></el-col></el-row>
         <el-row><el-col><span class="esprop">地址： {{areaCodeNameMapping[property.region[1]]}}{{areaCodeNameMapping[property.region[2]]}}{{property.address.descr}}</span></el-col></el-row>
         <el-row><el-col><span class="esprop">最新开盘: {{property.openTime}}</span></el-col></el-row>
            <el-row><el-col><span class="esprop">联系电话：</span><span class="phone">{{property.contactPhone}}</span></el-col></el-row>
            <el-row><el-col><span class="esprop">卖点:</span></el-col></el-row>
            <el-row><el-col><span class="esprop"><div v-html="property.salePoint"></div></span></el-col></el-row> 
        </el-col>
    </el-row>
   </el-card>
    
</div>
</template>

<script>
 import {bindToGlobal,getValFromGlobal} from '@/utils/global'
 import {areaCodeMap} from '@/api/commonFunc'

export default {
    name:"DetailBaseInfo",
    components:{
       
        },
    props:{
       property:{
           type:Object,
           default:{}
       }
    },
    
    watch:{
       
      
    },
    data(){
        
      
      return {
          areaCodeNameMapping:{}
      }
       
    },
    created(){
        if(!getValFromGlobal("areaCodeNameMapping")){
            areaCodeMap().then(res=>{
                bindToGlobal(res.data,"areaCodeNameMapping");
                this.areaCodeNameMapping = getValFromGlobal("areaCodeNameMapping")
            });
        }else{
            this.areaCodeNameMapping = getValFromGlobal("areaCodeNameMapping")
        }
       
       
      
    },
    computed:{
       

    },
    methods:{
       
    }
}
</script>

<style scoped>



 .card-list {
    font-size: 8px;
    text-align: center;
    
  }
  .blockBar{
      background: darkcyan;
      align-items: center;
      align-content: center;
  }
  .esname{
    margin-left: 5px;
    font-size: 20px;
}
.esprop{
    margin-left: 5px;
    font-size: 8px;
}
.price{
    color: firebrick;
    font-size: 20px;
}
.phone{
    color: firebrick;
    font-size: 20px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }
  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
</style>