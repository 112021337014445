<template>
<div>

    <el-card :body-style="{ padding: '10px' }">
        <el-row :gutter="30">
            <el-col :span="8"> 
                   <!-- <img :src="propertyBean.pics[0]" class="image"> -->
                <el-image  :src="url" fit="cover" >
                </el-image>
                 </el-col>
            <el-col :span="15">
            <el-row>
                <el-col :span="12">
                    <el-row><el-col ><span class="esname">{{propertyBean.name}} </span> <span class="type">[{{getPropertyTags(propertyBean.tags)}}]</span></el-col></el-row>
                    <el-row><el-col ><div>&nbsp;</div></el-col></el-row>
                    <el-row><el-col > <span class="type">{{getPropertyHouseTypes(propertyBean.houseTypes)}}&nbsp;&nbsp;&nbsp;&nbsp;{{propertyBean.area}}</span></el-col></el-row>
                    <el-row><el-col ><div>&nbsp;</div></el-col></el-row>
                    <el-row><el-col ><span class="type">[{{areaCodeNameMapping[propertyBean.region[1]]}}-{{areaCodeNameMapping[propertyBean.region[2]]}}]   {{propertyBean.address.descr}}</span></el-col></el-row>
                    <el-row><el-col ><div>&nbsp;</div></el-col></el-row>
                </el-col>
                <el-col :span="12">
                  <h4> 均价：<font color="red">{{propertyBean.avgPrice}}</font> 元/平米</h4>
                  </el-col>
            </el-row>
            <el-row>
                <el-col :span="22">
                  <el-tag v-for="item in propertyBean.tags" type="success">{{item}}</el-tag>
                  <el-tag v-for="item in propertyBean.advantages" type="danger">{{item}}</el-tag>
                </el-col>
            </el-row>
      
            </el-col>
        </el-row>

        <el-row>
          <el-col>购买理由: {{propertyBean.buyReason}}</el-col>
        </el-row>
      
    </el-card>

</div>
</template>
<style>
  .card-content {
    font-size: 8px;
    text-align: center;
  }

  .detail {
    width: 565px;
    height: 150px;
    float: right;
    margin-top: 25px;
    position: relative;
}
.esname{
    margin-left: 5px;
    font-size: 20px;
}
.type {
    font-size: 8px;
    margin-left: 4px;
}

  
</style>

<script>
import {getValFromGlobal} from '@/utils/global.js'

export default {
  name:"PropertyQueryListCard",
  props:{
    propertyBean: {
        type: Object,
        default () {
            return {}
        }
    }
  },
  data() {
    return {
     url:"https://www.angexinjia.com/uploads/20201024/6682397547a0a8c3a63204175d87c3d7.jpg"
    };
  },
  computed:{
    areaCodeNameMapping:function(){
       return getValFromGlobal("areaCodeNameMapping");
    }
    
  },
  methods:{
    getPropertyTags(tags){
      var tagStr = "";
      for(var i in tags){
        var temp = tags[i];
        if(i > 0){
          temp = " "+tags[i];
        }
        tagStr += temp;
      }
      return tagStr
    },
    getPropertyHouseTypes(types){
      var typeStr = "";
      for(var i = 0 ; i < types.length; i++){
        var temp = types[i];
        if(i > 0){
          temp = ","+types[i];
        }
        typeStr += temp;
      }
      return typeStr;
    }
  }
}
</script>