<template>
    <div>
        <el-row type="flex" align="middle">
          <el-col :span="4" ><div class="sitename">
           安个新家
          </div></el-col>
          <el-col :span="2"><div class="descr">团购更优惠</div></el-col>
          <el-col :span="2"><div v-show="showCity"><el-select v-model="chooseCity" @change="cityChanged">
          <el-option v-for="item in cityOptions" :key="item.value"
      :label="item.label"
      :value="item.value"></el-option>
          </el-select></div></el-col>
          <el-col :span="14"><div class="hotline"><h3>服务热线：400 000 0703</h3></div></el-col>
        </el-row>
    </div>
</template>

<script>
    import {getHotCity} from '@/api/commonFunc.js'
    export default {
        name: "pageHeader",
        components: {

        },
        props:{
            showCity:{
                type: Boolean,
                default:true,
            }
        },
    	data(){
    		return {
                chooseCity:'',
    			cityOptions:[]
    		}
    	},
        created() {
            let _this = this;
            getHotCity().then(res=>{
                this.cityOptions = res.data;
                if(this.cityOptions && this.cityOptions.length > 0){
                    this.chooseCity = this.cityOptions[0].value;
                    this.cityChanged();
                }
                
            })
        },
        methods:{
            cityChanged(){
               
                this.$emit('cityChanged',this.chooseCity);
            }
        }
    }
</script>

<style>
.sitename{
  text-shadow:-1px -px 1px #aaa,
  0px 4px 1px rgba(57, 138, 131, 0.4),
  4px 4px 5px rgba(57, 138, 131, 0.4),
  0px 0px 7px rgba(57, 138, 131, 0.4);
  text-indent: 0%;
  text-align:right;
  font-style:italic;
  font-weight:bold;
  font-size: 20px;
  font-family:"Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  color:#67C23A;
}
.descr{
    text-indent: 0%;
     text-align:center;
     font-size: 8px;
     font-weight:bold;
     color: #409EFF;
}
.hotline{
    text-align: right;
     font-size: 10px;
     font-weight:normal;
     color: #E6A23C;
}
</style>
