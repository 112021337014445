<template> 
 <div id="tabInfoDiv">

  <div id="navBar" ref=“navBarDiv” :class="{isFixed:istabBar}">
    <el-menu  class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="lpdt">楼盘动态</el-menu-item>
        <el-menu-item index="hxjs">户型介绍</el-menu-item>
        <el-menu-item index="lpxc">楼盘相册</el-menu-item>
        <el-menu-item index="lpdp">楼盘点评</el-menu-item>
        <el-menu-item index="lpwd">楼盘问答</el-menu-item>
        <el-menu-item index="lpms">楼盘描述</el-menu-item>
        <el-menu-item index="zbpt">周边配套</el-menu-item>
        <el-menu-item index="xxcs">详细参数</el-menu-item>
    </el-menu>
   
</div>
  <div id="mainPart">
    <!--楼盘动态-->
   <el-card id="lpdt">
    <el-row><el-col :span="2"><h2>楼盘动态</h2></el-col><el-col :span="18">&nbsp;</el-col><el-col :span="4"><el-button type="primary" @click="informMe">团购活动，最新消息通知我</el-button></el-col></el-row>
    <el-row><el-col :span="2">&nbsp;</el-col><el-col :span="22"> 
        <el-timeline :reverse="true">
    <el-timeline-item
      v-for="(activity, index) in property.propertyNews"
      :key="index"
      :timestamp="formatTime(activity.createGmt)">
      <div v-html="activity.content"></div>
      
    </el-timeline-item>
  </el-timeline></el-col></el-row>
    </el-card>

<!--户型介绍-->
     <el-card id="hxjs">
    <el-row><el-col :span="2"><h2>户型介绍</h2></el-col><el-col :span="22">&nbsp;</el-col></el-row>
    <el-row>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="22"> 
            <el-row :gutter="20">
                <el-col  :span="6" v-for="(info,index) in property.typeIntroPics" :key="index">
                <el-card>
                    <el-image style="width: 200px; height: 200px" :src="info.url" ></el-image>
      <el-row> <span>{{info.label}}</span></el-row>
      <el-row><span>参考总价：<span class="price">{{info.totalPrice}}</span>万</span></el-row>
      <el-row><span>参考首付：<span class="price">{{info.totalPrice * 0.3}}</span>万</span></el-row>
       <el-row><span>参考月供：<span class="price">计算中...</span></span></el-row>
       </el-card>
                </el-col>
               
            </el-row>
        </el-col>
    </el-row>
    </el-card>

<!--楼盘相册-->
      <el-card id="lpxc">
    <el-row><el-col :span="2"><h2>楼盘相册</h2></el-col><el-col :span="22">&nbsp;</el-col></el-row>
    <el-row>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="22"> 
             <el-row :gutter="20">
                 <el-col :span="6" >
                     <el-card>
                         <el-row>
                         <el-image v-if="property.effectPics.length > 0" style="width: 100px; height: 100px" :src="property.effectPics[0]"></el-image>
                         <el-image else style="width: 100px; height: 100px" :src="noImg"></el-image>
                         </el-row>
                         <el-row>
                         <span>效果图（{{property.effectPics.length}}）</span>
                         </el-row>
                     </el-card>
                 </el-col>

                 <el-col :span="6" >
                     <el-card>
                         <el-row>
                         <el-image v-if="property.realViewPics.length > 0" style="width: 100px; height: 100px" :src="property.realViewPics[0]"></el-image>
                         <el-image else style="width: 100px; height: 100px" :src="noImg"></el-image>
                         </el-row>
                         <el-row>
                         <span>实景图（{{property.realViewPics.length}}）</span>
                         </el-row>
                     </el-card>
                 </el-col>

                <el-col :span="6" >
                     <el-card>
                         <el-row>
                         <el-image v-if="property.templatePics.length > 0" style="width: 100px; height: 100px" :src="property.templatePics[0]"></el-image>
                         <el-image else style="width: 100px; height: 100px" :src="noImg"></el-image>
                         </el-row>
                         <el-row>
                         <span>样板间{{property.templatePics.length}}）</span>
                         </el-row>
                     </el-card>
                 </el-col>

                  <el-col :span="6" >
                     <el-card>
                         <el-row>
                         <el-image v-if="property.saleCenterPics.length > 0" style="width: 100px; height: 100px" :src="property.saleCenterPics[0]"></el-image>
                         <el-image else style="width: 100px; height: 100px" :src="noImg"></el-image>
                         </el-row>
                         <el-row>
                         <span>售楼处{{property.saleCenterPics.length}}）</span>
                         </el-row>
                     </el-card>
                 </el-col>
             </el-row>
        </el-col>
    </el-row>
    </el-card>

    <!--楼盘点评-->
      <el-card id="lpdp">
    <el-row><el-col :span="2"><h2>楼盘点评</h2></el-col><el-col :span="22">&nbsp;</el-col></el-row>
    <el-row>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="22"> 
            <el-row><el-col>
                <span>点评楼盘，可获得限量团购抵用券一份，直接抵扣房价
数量有限，先到先得</span>
</el-col></el-row>
            <el-row :gutter="20">
                <el-col>
                <el-button type="primary" @click="comment">我要点评</el-button>
                </el-col>
               
            </el-row>
        </el-col>
    </el-row>
    </el-card>

    <!--楼盘问答-->
     <el-card id="lpwd">
    <el-row><el-col :span="2"><h2>楼盘问答</h2></el-col><el-col :span="22">&nbsp;</el-col></el-row>
    <el-row>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="22"> 
            <el-row :gutter="20">
                <el-col >
                <el-button type="primary" @click="question">我要提问</el-button>
                </el-col>
               
            </el-row>
        </el-col>
    </el-row>
    </el-card>

    <!--帮你找房-->
      <el-card id="bnzf">
    <el-row><el-col :span="2"><h2>帮你找房</h2></el-col><el-col :span="22">&nbsp;</el-col></el-row>
    <el-row>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="22"> 
            <el-row :gutter="20">
                <el-col >
                <el-button type="primary" @click="findProperty">我要找房</el-button>
                </el-col>
               
            </el-row>
        </el-col>
    </el-row>
    </el-card>

        <!--楼盘描述-->
      <el-card id="lpms">
    <el-row><el-col :span="2"><h2>楼盘描述</h2></el-col><el-col :span="22">&nbsp;</el-col></el-row>
    <el-row>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="22"> 
            <el-row :gutter="20">
                <el-col >
                <div v-html="property.introduce"></div>
                </el-col>
               
            </el-row>
        </el-col>
    </el-row>
    </el-card>

       <!--周边配套-->
      <el-card id="zbpt">
    <el-row><el-col :span="2"><h2>周边配套</h2></el-col><el-col :span="22">&nbsp;</el-col></el-row>
    <el-row>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="22"> 
            <el-row :gutter="20">
                <el-col >
                  <BaiduMapPanel :property="property"></BaiduMapPanel>
                </el-col>
               
            </el-row>
        </el-col>
    </el-row>
    </el-card>

       <!--详细参数-->
      <el-card id="xxcs">
    <el-row><el-col :span="2"><h2>详细参数</h2></el-col><el-col :span="22">&nbsp;</el-col></el-row>
    <el-row>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="22"> 
            <el-row :gutter="20">
                <el-col :span="10">
                    <el-row><span>开发商：{{property.developer}}</span></el-row>
                    <el-row><span>物业公司：{{property.propertyMgrComp}}</span></el-row>
                    <el-row><span>最新开盘：{{property.openTime}}</span></el-row>
                    <el-row><span>交房时间：{{property.deliverTime}}</span></el-row>
                    <el-row><span>产权年限：{{property.availableYears}}</span></el-row>
                    <el-row><span>预售证许可证：{{property.preSaleCert}}</span></el-row>
                    <el-row><span>项目地址：{{property.address.descr}}</span></el-row>
                    <el-row><span>折扣：{{property.discount}}</span></el-row>
                </el-col>
                <el-col :span="2">&nbsp;</el-col>
                <el-col :span="10">
                     <el-row><span>规划户数：{{property.houseNum}}</span></el-row>
                    <el-row><span>车位情况：{{property.parks}}</span></el-row>
                    <el-row><span>水电情况：{{property.waterElect}}</span></el-row>
                    <el-row><span>容积率：{{property.capacityRate}}</span></el-row>
                    <el-row><span>绿化率：{{property.greenRate}}</span></el-row>
                    <el-row><span>楼盘性质：{{property.property}}</span></el-row>
                    <el-row><span>售卖状态：{{property.saleStatus}}</span></el-row>
                </el-col>
               
            </el-row>
        </el-col>
    </el-row>
    </el-card>


  </div>
 </div>
</template>
 
 
<script>
import no_pic from '@/assets/no_pic.png'
import {dateFormat} from '@/utils/timeUtil'
import BaiduMapPanel from './bdmap'
 export default {
  name:"DetailTabInfoPanel",
  components:{
      BaiduMapPanel
  },
  props:{
        property:{
           type:Object,
           default:{}
       }
  },
  created(){
      
    
  },
  data () {
   return {
    noImg:no_pic,
    istabBar: false,
    fixBarTop:0,
   }
  },
computed:{
    //    propertyActivities: function(){
    //        getActivitiesBy(this.property.id).then(res=>{
    //         this.propertyActivities = res.data;
    //         });
    //    }

    },
  methods: {
   // 添加一个方法 兼容
   handleScroll () {
//     let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
       let scrollTop = this.$parent.$el.scrollTop;
     // 固定导航
     let navBar = document.querySelector("#navBar");
     let mainPart = document.querySelector("#mainPart");
    //  let scrollTop = navBar.offsetParent.offsetTop;
     if (scrollTop > this.fixBarTop){
      this.istabBar = true
      mainPart.style.paddingTop = navBar.offsetHeight + "px";
     } else {
      this.istabBar = false
      mainPart.style.paddingTop = "0px";
     }
   },
informMe(){
    this.$emit("informMeBtnClicked");
},
comment(){
    this.$emit("commentBtnClicked");
},
question(){
    this.$emit("questionBtnClicked");
},
findProperty(){
    this.$emit("findPropertyBtnClicked");
},
    handleSelect(index){
       
        const targetElem = document.querySelector('#'+index)
        if(targetElem){
            targetElem.scrollIntoView(true);
            this.handleScroll();
        }
    },
    formatTime(longTypeDate){
        return dateFormat(longTypeDate);
    }
  },
  mounted () {
    
    window.addEventListener('scroll',this.handleScroll,true);
     
    this.$nextTick(()=> {
        setTimeout(()=> {


          this.fixBarTop = document.querySelector('#navBar').getBoundingClientRect().top;
          // Dom树加载完毕
           document.querySelector("#tabInfoDiv").offsetParent.addEventListener('scroll', this.handleScroll,true);
         

        },1000)
        })
       
  },
  destroyed () {
     document.querySelector("#tabInfoDiv").parentElement.removeEventListener('scroll', this.handleScroll);
//    window.removeEventListener('scroll', this.handleScroll) // 销毁页面时清除
  },

 }
</script>
 
 
<style>
#topPart{
width: 100%;
height: 100px;
background-color: yellow;
}

.isFixed {
position: fixed;
top: 0;
z-index: 10;
}
/* 
#navBar {
width: 100%;
height: 50px;
background-color: darkcyan;
} */

#mainPart {
width: 100%;
}

 #mainPart ul {
width: 100%;
}
 #mainPart ul li {
width: 100%;
height: 40px;
background-color: orange;
margin-bottom: 10px;
}

.price{
    color: red;
    font-size: 10px;
}
.borderStyle{
    border-radius: 0px;
    border:1px solid darkgray;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04) */
    align-content: center;
}
</style>