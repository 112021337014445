<template>
<div id="detail"> 
    
<DetailBaseInfo :property="property"></DetailBaseInfo>
<InquireAdvisorPanel @grouponBtnClicked="grouponBtnClicked"></InquireAdvisorPanel>
<DetailTabInfoPanel  :property="property" @informMeBtnClicked="informMeBtnClicked" @commentBtnClicked="commentBtnClicked" @questionBtnClicked="questionBtnClicked" @findPropertyBtnClicked="findPropertyBtnClicked"> </DetailTabInfoPanel>
<GrouponFormPanel :dialogVisible="showGrouponDialog==true" @grouponDialogClosed="grouponDialogClosed" :grouponInfo="grouponInfo"></GrouponFormPanel>

</div>   
</template>

<script>
import {getPropertyDetail } from '@/api/propertyQuery';
import { getUrlKey } from '@/utils/utils';
import DetailBaseInfo from './DetailBaseInfoCard';
import DetailTabInfoPanel from './DetailTabInfoPanel'
import InquireAdvisorPanel from './InquireAdvisorPanel'
import GrouponFormPanel from '../GrouponFormPanel'
export default {
    name:"PropertyDetailPanel",
    components:{
       DetailBaseInfo,
       DetailTabInfoPanel,
       InquireAdvisorPanel,
       GrouponFormPanel
        },
    props:{
        // chooseCity:String,
        property:{
            type:Object,
            default:{}
        }
    },
    created(){
       
    },
    watch:{
       
       
    },
    data(){
      
      return {
    			
        isFixed: false,
        offsetTop: 0,
        grouponInfo:{},
        showGrouponDialog:false
       
    		}
       
    },
    created(){
    
      
    },
   
    computed:{
       

    },
    methods:{
        
        seeMore(){
            console.log('see more');
            this.$emit('switchPanel','search');
        },
      grouponBtnClicked(){
        
        this.grouponInfo = {
          type:'报名团购',
          estateid:this.property.id,
          content:"我想要报名团购",
          information:'报名团购可享限量内部优惠<span class="red">额外折上折!</span><br/><span class="red">261</span>人已报名！',
          phone:'',
          showContent:false,
          contentPlaceHoder:'请输入内容'
        }
         this.showGrouponDialog = true; 
        
       
    },
    informMeBtnClicked(){
      this.grouponInfo = {
          type:'报名团购',
          estateid:this.property.id,
          content:"我想要报名团购",
          information:'最新的优惠活动信息，我们将第一时间通知您！<br/><span class="red">176</span>人已关注！',
          phone:'',
          showContent:false,
          contentPlaceHoder:'请输入内容'
        }
      this.showGrouponDialog = true; 
       
    },
    commentBtnClicked(){
      this.grouponInfo = {
          type:'用户点评',
          estateid:this.property.id,
          content:'',
          information:'为保证团购抵用券正常发放和使用，请留下您的真实手机号，我们将保证您的隐私.',
          phone:'',
          showContent:true,
          contentPlaceHoder:'请输入内容'
        }
       this.showGrouponDialog = true; 
       
    },
    questionBtnClicked(){
      this.grouponInfo = {
          type:'楼盘问答',
          estateid:this.property.id,
          content:'',
          information:'请留下您的真实手机号，方便回复后通知您。',
          phone:'',
          showContent:true,
          contentPlaceHoder:'请输入内容'
        }
       this.showGrouponDialog = true; 
    },
    findPropertyBtnClicked(){
       this.grouponInfo = {
          type:'我要找房',
          estateid:this.property.id,
          content:'',
          information:'请留下您的真实手机号，方便回复后通知您。',
          phone:'',
          showContent:true,
          contentPlaceHoder:'例：市中心，近地铁，总价150万的住宅。或：市中心，低总价包租的公寓，或商铺，投资用'
        }
       this.showGrouponDialog = true; 
    },
     grouponDialogClosed(){
       this.showGrouponDialog = false;
      }
        
    },

    destroyed() {
			// 离开页面 关闭监听 不然会报错
			window.removeEventListener('scroll', this.handleScroll);
  },
  
}
</script>

<style>

 .card-list {
    font-size: 8px;
    text-align: center;
    
  }
  .blockBar{
      background: darkcyan;
      align-items: center;
      align-content: center;
  }
  

	.nav_fixed {
		position: fixed;
		z-index: 2;
		top: 0;
		width: 77.2%;
	}

  .red{
    color: red;
    font-size: 12px;
}
</style>