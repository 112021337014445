<template>
    <el-card>
        <el-row>
            <el-col :span="8">
                <el-image
      style="width: 80px; height: 80px"
      :src="advisor.avatar"
      fit="fill"></el-image>
            </el-col>
            <el-col :span="16">
                <el-row><el-col :span="12">{{advisor.nickname}}</el-col> <el-col :span="12"> <el-tag type="success">+微信</el-tag></el-col></el-row>
                <el-row><el-col :span="24" class="intro">{{advisor.experience}}</el-col></el-row>
                <el-row><el-col :span="24" class="intro">联系方式：{{advisor.contact}}</el-col></el-row>
            </el-col>
        </el-row>
    </el-card>
</template>
<script>
export default {
    name:"AdvisorCard",
    components:{

    },
     props:{
       advisor:{
           type: Object,
            default () {
                return {}
            }
       }
    },
    data(){
        return{
            
        }
    }
    
}
</script>
<style scoped>
.intro {
    font-size: 8px;
    text-align: center;
    
  }

</style>