<template>
    <div class="info">
        <el-row type="flex" align="middle" >
          <el-col :span="24" >
              <div class="info">
          &nbsp;
         </div>
          </el-col>
          </el-row>
        <el-row type="flex" align="middle" >
          <el-col :span="24" >
              <div class="info">
           免责声明：网站所有信息均由公开渠道获取，最终以开发商提供及政府部门登记备案为准。本站所有面积未经特殊说明均为建筑面积。所有的团购优惠信息最终以售楼处信息为准。
         </div>
          </el-col>
          </el-row>
           <el-row type="flex" align="middle" >
          <el-col :span="24" >
              <div class="info">
          开发商授权丨趣买房网丨售楼直通车 
          </div>
          <div style="width:300px;margin:0 auto; padding:20px 0;">
		 		<a target="_blank" href="https://beian.miit.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                 <!-- <img src="" style="float:left;"/> -->
                 <el-image style="float:left;" :src="img" fit="fit"></el-image>
                 <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">蜀ICP备2022026145号</p>
                 </a>
		 	</div>
          </el-col>
          </el-row>
           <el-row type="flex" align="middle" >
          <el-col>
              <!-- <el-link href="http://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020180410080032000002461468-SAIC_SHOW_310000-4028e4cb6ac5c06c016b470d3dde4800582&signData=MEQCIAVeReVdoENRftgLQiWIFFP3yzoBvHU4yEzk0YXPDA3xAiAsljJAdZEvWhNMYqrU5gHtESs6CIbq3PjH6nqboUj8xw==" target="_blank">
              <el-image style="width: 62px; height: 74px" :src="img"
      fit="fit"></el-image>
      </el-link> -->
          </el-col>
        </el-row>
    </div>
</template>

<script>

    import gs from '@/assets/beian.png'
    export default {
        name: "pageFooter",
        components: {

        },
    	data(){
    		return {
    			img:gs
    		}
    	}
    }
</script>

<style>
.info{
  text-indent: 0%;
  text-align:center;
  font-style:normal;
  font-weight:normal;
  font-size: 7px;
  font-family:"Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  color: rgb(216, 222, 228);
  background-color: rgb(49, 154, 180);
}

</style>
