const path = require('path');
const fs = require('fs');
const glob = require('glob');
const merge = require('webpack-merge');

const pagePath = path.resolve(__dirname, "..", "pages");
const entryPath = path.resolve(__dirname, "..", "entry");
const configPath = path.resolve(__dirname, "..", "config");

 console.log(process.env.PLATFORM); 
 /*获取配置*/
 
 exports.config = function(filename,field=""){
    const file = path.join(configPath, filename);
    let value = require(file);
    if(field!==""){
        value = value[field];
    }
    return value;
}
 
/*获取多页面配置选项*/
exports.getPages =function(){
    let pages = {};
	const platform = process.env.PLATFORM;
    //获取所有vue文件
    let files = glob.sync(`${pagePath}/${platform}/*.vue`);
    if(files.length < 1){
        console.error("util getPages no file");
    }
    files.forEach(filepath=>{
        const extname = path.extname(filepath);
        const basename = path.basename(filepath, extname);
		
		
        //统一入口文件保存路径
        const entry = path.join(entryPath,platform,`${basename}.js`);//绝对路径
        //自动生成入口文件
        const exists = fs.existsSync(entry);
        console.log(exists, entry);
        if(!exists){
            let code = `import Vue from 'vue';\n`;
            code += `import App from '${filepath}';\n`;
            code += `Vue.config.productionTip = false;\n`;
            code += `new Vue({render:h=>h(App)}).$mount('#${basename}');`;
            fs.writeFileSync(entry, code);
        }
        //页面配置选项
        const template = "index.html";
        const filename = `./${platform}/${basename}.html`;
        const chunks = ['chunk-vendors', 'chunk-common', basename];
        const chunksSortMode = "manual";
        const minify = {};
        const inject = true;
        //自定义页面数据
        const pageData = this.config("page.json", basename) || {};
        if(pageData.title === undefined){
            Object.assign(pageData, {title:basename});
        }
        if(pageData.idname === undefined){
            Object.assign(pageData, {idname:basename});
        }
		let conf = {entry:entry, template:template, filename:filename, pageData:pageData, chunks:chunks, chunksSortMode:chunksSortMode, minify:minify, inject:inject};
		  if (process.env.NODE_ENV === 'production') {
   				conf = merge(conf, {
   				 minify: {
     			removeComments: true, // 删除 html 中的注释代码
     			collapseWhitespace: true, // 删除 html 中的空白符
     				// removeAttributeQuotes: true // 删除 html 元素中属性的引号
   			 },
    	chunksSortMode: 'manual'// 按 manual 的顺序引入
   })
  }
		console.log(pageData);
		
        pages[basename] = conf;
    });
    return pages;
};

exports.getUrlKey = function(name,url){
    　　return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ""])[1].replace(/\+/g, '%20')) || null
    
};

exports.random = function(min, max) {
 
    return Math.floor(Math.random() * (max - min)) + min;

  }


exports.getQueryString = function(name){
    
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var reg_rewrite = new RegExp("(^|/)" + name + "/([^/]*)(/|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        var q = window.location.pathname.substr(1).match(reg_rewrite);
        if(r != null){
            return unescape(r[2]);
        }else if(q != null){
            return unescape(q[2]);
        }else{
            return null;
        }
        
}

// module.exports = {config,getPages,getUrlKey}

// export {config,getPages,getUrlKey}