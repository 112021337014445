<template>
<div id="inquireAdvisorsMainPanel">
 <el-card id="advisors">
    <el-row><el-col :span="6"><h2>咨询置业顾问</h2></el-col><el-col :span="22">&nbsp;</el-col></el-row>
    <el-row :gutter="10"><el-col v-for="item in advisorList" :span="6"> 
         <advisor-card  :advisor="item"></advisor-card>
        </el-col></el-row>
    
    <el-row><el-col><el-button type="primary" @click="btnClicked">报名团购</el-button></el-col></el-row>
        
    </el-card>
    
</div>

</template>
<script>
import {getAdvisors} from '@/api/commonFunc.js'
import AdvisorCard from '../AdvisorCard'

 export default {
    name:"InquireAdvisorPanel",
    components:{
        AdvisorCard
    },
    data() {
       return {
           showDialog:false,
           advisorList:[]
       }
    },
     created(){
     getAdvisors().then(res=>{
       this.advisorList = res.data;
     });
      
    },
    methods:{
       btnClicked(){
         this.$emit("grouponBtnClicked");
       }
    }
}
</script>
<style scoped>
</style>